import React from "react";
import Header from "../../common/header";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./mytaxicab.css";
import Slider from "react-slick";

const Mytaxicab = () => {
  const data = {
    title: "My Taxi Cab",
    subText: "Doing What We Do Best! ",
  };

  let settings = {
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2000,
    speed: 1000,
    focusOnSelect: true,
    // arrows: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    arrows: false,

    responsive: [
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div style={{ minHeight: "2500px ", marginTop: "100px" }}>
      <Header data={data} />
      <div className="container mytaxicab" style={{ marginTop: "120px" }}>
        <p>
          This app is designed and developed by the company for the exclusive
          use of drivers. We provide a user friendly platform for drivers to
          make their trips easier. Our specialised technology helps drivers
          simplify their work and enhance their earnings. We also assist them to
          become empowered entrepreneurs and earn higher without any
          commissions.
        </p>
        <h3> Unique Features Of The Application </h3>
        <div className="title-line-contact " />

        <ul>
          <li>Subscription plan for drivers and commuters. </li>

          <li>Self booking-any time, any location</li>
          <li>Advance booking for outstation rides</li>
          <li>No commissions and unwanted charges</li>
          <li>All transactions with E-Bill Records.</li>
          <li>24/7 friendly customer care support </li>
          <li>Training to kick start own business. </li>
          <li>Refer and Earn</li>
          <li>Customer Leads</li>
          <li>Coupon</li>
          <li>Account Wallet </li>
          <li>GST registration </li>
          <li>Plan of trips</li>
          <li>Games</li>
        </ul>

        <h3>Some Issues Faced by Drivers With Other Organisations</h3>
        <div className="title-line-contact " />

        <ul>
          <li>
            Drivers remain unaware of the destination until the commuter gets in
            the cab and enters the OTP.
          </li>
          <li>
            Drivers are not aware of the distance and time of the ride until
            they accept the commuter.{" "}
          </li>{" "}
          <li>
            Drivers are not informed of the actual fare to be received for each
            trip.
          </li>{" "}
          <li>
            Drivers are unaware of the amount of fare paid by the commuter
            incase of online payments.{" "}
          </li>{" "}
          <li>
            If the driver rejects the trip after the booking, he is subjected to
            pay the penalty.
          </li>{" "}
          <li>
            Huge commission is charged (some time up to 30%) by the aggregators.
          </li>
          <li>
            Drivers are expected to work for long hours to reach their minimum
            daily targets.
          </li>
        </ul>

        <h3>How We Are Different </h3>
        <div className="title-line-contact " />

        <ul>
          <li>
            The drivers shall view the destination, travel time and the fare for
            each trip in the application. He can schedule accordingly and accept
            the ride or not.{" "}
          </li>
          <li>
            Drivers are not charged with the penalty for rejecting a ride.{" "}
          </li>
          <li>
            The company does not pressurise the drivers to achieve their daily
            targets.{" "}
          </li>
          <li>
            Drivers are not charged with commissions for each trip. The fare
            displayed in the application is the final amount for both the
            drivers and commuters. The entire amount is directly received by the
            driver.{" "}
          </li>
          <li>
            The driver is expected to pay only a bare minimum amount of monthly
            subscription fee.
          </li>
          <li>
            Drivers are provided with the option to view and download the
            summary of trips made by them.{" "}
          </li>
          <li>
            Drivers shall enjoy complete transparency in billing and receipts
            for each trip.{" "}
          </li>
        </ul>
   

        <img src="banner2.jpg" alt="" />

        <h3>Our Specialities</h3>
        <div className="title-line-contact " />
        <h5>Taxi Services</h5>
        <p>
          Drivers shall provide taxi services within and outside the city
          limits. We also provide spot booking facility where customers can book
          their rides instantly from anywhere and at any time. Drivers can also
          book rides on behalf of their customers.{" "}
        </p>
        <h5>Rental Services</h5>
        <p>
          Drivers shall provide rental car service to their customers. Drivers
          can set their rental packages starting from one hour upto 12 hours.
          They can also provide options to the customers to extend the package
          limits with nominal charges. Commuters shall be provided with the
          option to hire local cabs as long as they want and even allow them to
          travel multiple locations within city limits. Our flexible booking
          option allows customers to book cabs instantly or upto 48 hours in
          advance.{" "}
        </p>
        <h5>Outstation Rides</h5>
        <p>
          Drivers shall provide one way and round trip outstation rides to
          commuters at Government approved rates. Cab booking options shall be
          provided one hour before the trip or upto 7 days in advance. Customers
          can also be provided with options to choose from hatchbacks, sedans,
          SUV's and tempo transport.
        </p>
        <h3>Our Collection Of Fleet </h3>
        <div className="title-line-contact " />
        <h5>Hatchbacks</h5>
        <p>
          Hatchbacks have more trunk space when compared to sedans and customers
          can also completely relax, push the seat back and enjoy the drive
          comfortably.{" "}
        </p>
        <h5>Sedan</h5>
        <p>
          When the overview performance of a car is considered, Sedan stands at
          the top in terms of Acceleration, Fuel Consumption, Breaking System,
          and Speed. Lighter the vehicle, easier to drive and fuel consumption
          is also less when compared to other cars.{" "}
        </p>
        <h5>SUV's</h5>
        <p>
          SUV's provide utmost safety because of the inbuilt sturdy frames.
          These vehicles ensure durable performance and is the perfect companion
          for road trips.{" "}
        </p>
        <h5>Tempo Transport</h5>
        <p>
          Tempo transport lets customers enjoy the ride despite the weather
          condition. The perfect companion to take family and friends out on a
          trip at affordable prices.{" "}
        </p>
      </div>
      <div className="mytaxicabslider  ">
        <ul className="text-center">
          <Slider {...settings}>
            <li>
              <img
                src="mtc5.png"
                alt=""
                style={{ height: "80px", width: "80px" }}
              />
              <h5 className="mt-3">No Commission </h5>
            </li>
            <li>
              <img
                src="mtc2.jpg"
                alt=""
                style={{ height: "80px", width: "80px" }}
              />
              <h5 className="mt-3">More Earnings </h5>
            </li>
            <li>
              <img
                src="mtc3.png"
                alt=""
                style={{ height: "80px", width: "80px" }}
              />
              <h5 className="mt-3">Govt. Rates</h5>
            </li>
            <li>
              <img
                src="mtc4.png"
                alt=""
                style={{ height: "80px", width: "80px" }}
              />
              <h5 className="mt-3">Safe Ride</h5>
            </li>
            <li>
              <img
                src="mtc5.png"
                alt=""
                style={{ height: "80px", width: "80px" }}
              />
              <h5 className="mt-3"> No Commission </h5>
            </li>
            <li>
              <img
                src="mtc6.png"
                alt=""
                style={{ height: "80px", width: "80px" }}
              />
              <h5 className="mt-3">Flexible Billing</h5>
            </li>
          </Slider>
        </ul>
      </div>
    </div>
  );
};

export default Mytaxicab;
