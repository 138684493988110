const data = [
  {
    img: "images/cars/car/sedan.jpg",
    icon: "images/cars/sedanic.png",
    carName: "sedan",
    title: "Sedan",
    text:
      "When the overview performance of a car is considered, Sedan stands at the top in terms of Acceleration, Fuel Consumption, Breaking System, and Speed. Lighter the vehicle, easier to drive and fuel consumption is also less when compared to other cars.  ",
    maxPerson: 4,
  },
  {
    img: "images/cars/car/hatchback.jpg",
    icon: "images/cars/hatchbackic.png",
    carName: "hatchback",
    title: "Hatchback",
    text:
      "Hatchbacks have more trunk space when compared to sedans and customers can also completely relax, push the seat back and enjoy the drive comfortably. ",
    maxPerson: 4,
  },
  {
    img: "images/cars/car/suv.jpg",
    icon: "images/cars/suvic.png",
    carName: "suv",
    title: "SUV",
    text:
      "SUV's provide utmost safety because of the inbuilt sturdy frames. These vehicles ensure durable performance and is the perfect companion for road trips. ",
    maxPerson: 6,
  },
  {
    img: "images/cars/car/tempo.jpg",
    icon: "images/cars/tempo.png",
    carName: "tempo",
    title: "Tempo",
    text:
      "Tempo transport lets customers enjoy the ride despite the weather condition. The perfect companion to take family and friends out on a trip at affordable prices.",
    maxPerson: 10,
  },
  {
    img: "images/cars/car/auto.jpg",
    icon: "images/cars/autoic.png",
    carName: "auto",
    title: "Auto",
    text: "text part",
    maxPerson: 3,
  },
  {
    img: "images/cars/car/tataace.jpg",
    icon: "images/cars/tataace.png",
    carName: "tataace",
    title: "Tata Ace",
    text: "text part",
    maxPerson: "1 ",
  },
];

export default {
  carServices: data,
};
