import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";

class CareersModal extends Component {
  state = {};
  render() {
    const props = this.props;

    return (
      <div>
        <Modal
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Job Description
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h4>Responsibilities</h4>
            <ul>
              {props.data.responsibilities.map((r) => (
                <li key={r}>{r}</li>
              ))}
            </ul>

            <h4>Skills</h4>

            <ul>
              {props.data.skills.map((s) => (
                <li key={s}>{s}</li>
              ))}
            </ul>
            
         
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn"
              onClick={props.onHide}
              style={{ background: "#393185", color: "#ffff" }}
            >
              Close
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default CareersModal;
