import React, { Component } from "react";

import CoverPage from "./pages/covepage2";
import MainPage from "./pages/mainPage";

import CarSlider from "../components/pages/carSlider";
import Testimonials from "../components/pages/testimonials";
// import DownloadApp from "../components/pages/downloadApp";

class Blog extends Component {
  render() {
    return (
      <main
        className="overflow-hidden"
        style={{ minHeight: "300px", marginTop: "80px" }}
      >
        <CoverPage />
        <MainPage />
        <CarSlider />

        <Testimonials />
        {/* <DownloadApp /> */}
      </main>
    );
  }
}

export default Blog;
