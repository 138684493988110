const data = [
  {
    text:
      " Timely reporting, safe and secure driving, extremely polite and respectful driver. Haven't come across a better online car booking service. Will plan all my outstation trips with Indian Taxis",
    name: "Rajkumar",
    userType: "user",
  },
  {
    text:
      "Tried Indian Taxis for the first time and it was truly an amazing and hassle free experience!",
    name: "Maya",
    userType: "user",
  },
  {
    text:
      "Thank you Indian Taxis for the great ride! Me and my family really had a wonderful time. ",
    name: "Brijesh",
    userType: "user",
  },
  {
    text:
      "I had an early morning flight to catch and chose to opt for an online taxi booking from Indian Taxis. The package and the driver made it a safe and comfortable trip to the airport!!Thank you Indian Taxis for the great ride! Me and my family really had a wonderful time. ",
    name: "Riya",
    userType: "user",
  },
  {
    text:
      "We were new to the city and were looking forward to book taxi in Bangalore. The Rental Services from Indian Taxis helped us visit multiple places in the same cab. The helpful driver also gave us a free tour of the iconic places along the way ",
    name: "Shahid",
    userType: "user",
  },
  {
    text:
      "Taxi booking to go around the city has never been this easy! Thank you Indian Taxis for giving us the best time of our lives. ",
    name: "Deepak",
    userType: "user",
  },
];

export default {
  testimonials: data,
};
