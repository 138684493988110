import React, { Component } from "react";
import Tabletop from "tabletop";

class SocialMedia extends Component {
  state = {
    data: "",
  };

  componentDidMount() {
    // https://docs.google.com/spreadsheets/d/1lXXvz1F8i71rk7YwVAjBxzTFvVNmFwlyyVJSEr4Az1Y/edit?usp=sharing
    //docs.google.com/spreadsheets/d/1lXXvz1F8i71rk7YwVAjBxzTFvVNmFwlyyVJSEr4Az1Y/edit#gid=0
    //https://docs.google.com/spreadsheets/d/1Eyp0cxy_XqRiR9w6tC_xmTe5KHFPsuPJUjKN1KnRjmQ/edit?usp=sharing

    https: Tabletop.init({
      key: "1lXXvz1F8i71rk7YwVAjBxzTFvVNmFwlyyVJSEr4Az1Y",
      callback: (googleData) => {
        console.log("google sheet data", googleData);
        this.setState({ data: googleData });
      },
      simpleSheet: true,
    });
  }

  render() {
    console.log(this.state);
    return (
      <div className="text-center" style={{ marginTop: "150px" }}>
        {/* <iframe
          id="twitter-widget-0"
          scrolling="no"
          frameborder="0"
          allowtransparency="true"
          allowfullscreen="true"
          class=""
          style="position: static; visibility: visible; width: 550px; height: 245px; display: block; flex-grow: 1;"
          title="Twitter Tweet"
          src="https://connect.facebook.net/en_GB/sdk.js#xfbml=1&version=v8.0"
          data-tweet-id="1252351837304311815"
        ></iframe> */}

        <div className=" row justify-content-center">
          {this.state.data.length !== 0 &&
            this.state.data.map((d) => (
              <div
                className="col-lg-5 m-1 mb-3 col-9 overflow-hidden"
                key={this.state.data.indexOf(d)}
              >
                <iframe
                  title={this.state.data.indexOf(d)}
                  src={d.url}
                  style={{
                    height: "766px",
                    width: "100%",
                    // allowfullscreen: "true",
                    overflow: "hidden",
                    scrolling: "no",
                    frameborder: "0",
                    border: "none",
                    allowTransparency: "true",
                    allow: "encrypted-media",
                  }}
                />
              </div>
            ))}

          {/* <div className="col-lg-5 m-1">
            <iframe
              src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2FMeteredTaxiCab%2Fposts%2F344120687032383&show_text=true&width=552&height=691&appId"
              style={{
                height: "700px",
                width: "100%",

                overflow: "hidden",
                scrolling: "yes",
                frameborder: "0",
                border: "none",
                allowTransparency: "true",
                allow: "encrypted-media",
              }}
            ></iframe>
          </div> */}
        </div>
      </div>
    );
  }
}

export default SocialMedia;
