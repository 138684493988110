import React from "react";

import "./App.css";

import NavBar2 from "./components/navBar/navBar2";

import {
  Route,
  Switch,
HashRouter
 
  // Redirect
} from "react-router-dom";

import Blog from "./components/blog";

import About from "./components/pages/about";
//produsts
import Mytaxicab from "./components/pages/ourServices/mytaxicab";
import IndianTaxis from "./components/pages/ourServices/indianTaxis";

import Careers from "./components/pages/careers";

import Footer from "./components/pages/footer";
import Testimonials from "./components/pages/testimonials";
import ContactUs from "./components/pages/contactUs";
import SocialMedia from "./components/pages/socialMedia";



const App = () => {
  return (
    <div>
      <main>
        {/* <NavBar2 /> */}
   <HashRouter basename="/"> 
        <Route component={NavBar2}/>
        <Switch>
          <Route path="/" exact component={Blog} />

          <Route path="/about" exact component={About} />
          <Route path="/careers" exact component={Careers} />
          <Route path="/mytaxicab" exact component={Mytaxicab} />
          <Route path="/indiantaxis" exact component={IndianTaxis} />
          <Route path="/testimonials" exact component={Testimonials} />
          <Route path="/socialmedia" exact component={SocialMedia} />
          <Route path="/contactus" exact component={ContactUs} />
        </Switch>
  
   
        <Footer />
     
   </HashRouter > 
      
      
      </main>
    </div>
  );
};

export default App;
