const marketing = [
  {
    titel: "Digital Marketing Executive ",
    description:
      "Position PPC  Marketing Executive with Experience 1+ years.",
    jobDescription: {
      responsibilities: [
        "Proven track records in generating leads and conversions.",
        "Must have paid marketing strategy.",
        "We are looking for a PPC (Pay Per Click) Executive to run PPC campaigns and monitor paid search budgets.",
        "Experience in working on Google ads, Facebook ads, instagram ads",
       "Familiarity with multiple platforms (e.g. Google Ads, Facebook Ads, instagram Ads) is preferred.",
       "should have basic knowledge about SEO,SMM,SEM"
      ],
      skills: ["SEM","SMM","SEO","PPC", "Experience in running Facebook ad campaigns & google ads"],
    },
  },


 
];

const humanResources = [
  // {
  //   titel: "title 1 humanResources",
  //   description:
  //     " Testing identities rather than values is bad because an implementation  identities ",
  //   jobDescription: {
  //     responsibilities: [
  //       "responsibilities for title 1",
  //       "responsibilities for title 2",
  //       "responsibilities for title 3",
  //       "responsibilities for title 4",
  //       "responsibilities for title 5",
  //       "responsibilities for title 6",
  //     ],
  //     skills: ["java", "react"],
  //   },
  // },
 
];

const technology = [


];

const products = [

 
];

export default {
  marketingdata: marketing,
  humanResourcesdata: humanResources,
  technologydata: technology,
  productsdata: products,
};
