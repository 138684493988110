import React from "react";

const MainPage = () => {
  return (
    <div
      className="justify-content-cente mainPage  "
      style={{
        paddingBottom: "80px",
        paddingTop: "30px",
        marginTop: "60px",
        marginLeft: "10px",
        marginRight: "10px",
        backgroundColor: "white",
      }}
    >
      {/* {products.products.map((p) => console.log(var { app: a } = p.app))} */}
      {/* {products.products.map((p) => (
        <CardsMain data={p} key={p.title} />
      ))} */}
      <div className="row  p-md-2" style={{ marginBottom: "10px" }}>
        <div className="col-md-6 col-12 text-center ">
          <img
            src="mainpageIT.png"
            alt=""
            style={{ width: "90%", height: "100%" }}
          />
        </div>
        <div className="col-md-6 col-12 pl-3">
          <h3 className="pt-2 mt-2">Indian Taxis</h3>
          <div className="title-line-contact "></div>
          <p className="pr-4 mr-4 ">
            This app is designed and developed by the company for the exclusive
            use of drivers. We provide a user friendly platform for drivers to
            make their trips easier. Our specialised technology helps drivers
            simplify their work and enhance their earnings. We also assist them
            to become empowered entrepreneurs and earn higher without any
            commissions.
          </p>

          <a
            href="https://indiantaxis.in/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <button className="btn mt-4 ">
              <h6>Go to web site</h6>
            </button>
          </a>
        </div>
      </div>

      {/* ..................... */}
      <div className="d-none d-md-block">
        <div className="row m-5 p-2 ">
          <div className="col-md-6 col-12">
            <h3>My Taxi Cab</h3>
            <div className="title-line-contact "></div>
            <p style={{ fontSize: "20px" }}>
              This intuitive and user friendly taxi app is focused on providing
              quick, hassle free and reliable mobility solutions to commuters.
              We provide cab services all over India 24*7 and 365 days of the
              year. Instant booking for outstation rides are also provided.
            </p>

            <a
              href="https://mytaxicab.in/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button className="btn mt-4 ">
                <h6>Go to web site</h6>
              </button>
            </a>
          </div>
          <div className="col-md-6 text-center col-12">
            <img
              src="mtc.png"
              alt=""
              style={{ width: "80%", height: "100%" }}
            />
          </div>
        </div>
      </div>

      {/* for mobile */}

      <div className="d-md-none">
        <div className="row  ">
          <div className="col-md-6 col-12 text-center">
            <img src="mtc.png" alt="" />
          </div>
          <div className="col-md-6  col-12">
            <h3>My Taxi Cab</h3>
            <div className="title-line-contact "></div>
            <p>
              This intuitive and user friendly taxi app is focused on providing
              quick, hassle free and reliable mobility solutions to commuters.
              We provide cab services all over India 24*7 and 365 days of the
              year. Instant booking for outstation rides are also provided.
            </p>

            <a
              href="https://mytaxicab.in/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button className="btn mt-4 ">
                <h6>Go to web site</h6>
              </button>
            </a>
          </div>
        </div>
      </div>
      {/* for mabile */}
    </div>
  );
};

export default MainPage;
