import React, { Component } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./carSlider.css";

import Slider from "react-slick";
import carServiceData from "../../data/carServices";

function NextArrow(props) {
  const { className, style, onClick } = props;

  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",

        fontSize: "60px",
        height: "80px",
        width: "80px",
        color: "var(--rstlog-color)",
        right: "-100px",
      }}
      onClick={onClick}
    >
      <i className="fa fa-angle-right"></i>
    </div>
  );
}

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,

        // background: "#393185",
        // color: "black",
        display: "block",

        fontSize: "60px",
        height: "80px",
        width: "80px",
        left: "-100px",
        color: "var(--rstlog-color)",
      }}
      onClick={onClick}
    >
      {" "}
      <i className="fa fa-angle-left"></i>
    </div>
  );
}

class CarSlider extends Component {
  state = {
    nav1: null,
    nav2: null,
    data: [],
  };

  componentDidMount() {
    const data = carServiceData.carServices;
    this.setState({ data });
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2,
    });
  }

  render() {
    let settings = {
      infinite: true,
      autoplay: true,
      autoplaySpeed: 15000,
      speed: 1000,
      focusOnSelect: true,
      // arrows: true,
      slidesToShow: 2,
      slidesToScroll: 1,
      arrows: false,

      responsive: [
        {
          breakpoint: 960,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    return (
      <div
        style={{
          background: "white",
          paddingBottom: "56px",
          marginBottom: "-16px",
        }}
      >
        <div className="header">
          <h1>Our Services</h1>
          <div className="title-line" />

          <div className="slider1">
            <ul className="">
              <Slider
                {...settings}
                asNavFor={this.state.nav2}
                ref={(slider) => (this.slider1 = slider)}
                dots={true}
              >
                {this.state.data.map((d) => (
                  <li
                    key={this.state.data.indexOf(d)}
                    className="align-self-center"
                  >
                    <img
                      src={d.icon}
                      alt="icon"
                      style={{ width: "100px", height: "100px" }}
                    />
                  </li>
                ))}
              </Slider>
            </ul>
          </div>
        </div>
        <Slider
          asNavFor={this.state.nav1}
          ref={(slider) => (this.slider2 = slider)}
          {...settings}
          nextArrow={<NextArrow />}
          prevArrow={<PrevArrow />}
          // slidesToShow={2}
          arrows={true}
          swipeToSlide={true}
          focusOnSelect={true}
          className="slider2"
        >
          {this.state.data.map((d) => (
            <div key={this.state.data.indexOf(d)}>
              <div className="m-4 cards ">
                <img
                  alt={"users here"}
                  src={d.img}
                  style={{ marginBottom: "50px" }}
                />

                <div className="overlay">
                  <div className="mt-1 ml-3 mr-3">
                    <label className="title ml-3   mt-1 md-h1 float-left ">
                      {d.title}
                    </label>
                    <label className="max mr-3 mt-1  float-right align-middle ">
                      <i className="fa fa-user "></i>&nbsp;&nbsp;&nbsp;Max:{" "}
                      {d.maxPerson}
                    </label>
                  </div>

                  <div style={{ marginTop: "55px" }}>
                    <img
                      src={d.icon}
                      alt=""
                      style={{ width: "90px", height: "90px" }}
                    />
                    {/* <h3 className="mb-3">{d.title}</h3> */}
                    <p>{d.text}</p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    );
  }
}

export default CarSlider;
